import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Guitar
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtMjAgNyAxLjctMS43YTEgMSAwIDAgMCAwLTEuNGwtMS42LTEuNmExIDEgMCAwIDAtMS40IDBMMTcgNHYzWiIgLz4KICA8cGF0aCBkPSJtMTcgNy01LjEgNS4xIiAvPgogIDxjaXJjbGUgY3g9IjExLjUiIGN5PSIxMi41IiByPSIuNSIgZmlsbD0iY3VycmVudENvbG9yIiAvPgogIDxwYXRoIGQ9Ik02IDEyYTIgMiAwIDAgMCAxLjgtMS4ybC40LS45QzguNyA4LjggOS44IDggMTEgOGMyLjggMCA1IDIuMiA1IDUgMCAxLjItLjggMi4zLTEuOSAyLjhsLS45LjRBMiAyIDAgMCAwIDEyIDE4YTQgNCAwIDAgMS00IDRjLTMuMyAwLTYtMi43LTYtNmE0IDQgMCAwIDEgNC00IiAvPgogIDxwYXRoIGQ9Im02IDE2IDIgMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/guitar
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Guitar = createLucideIcon('GuitarIcon', [
  ['path', { d: 'm20 7 1.7-1.7a1 1 0 0 0 0-1.4l-1.6-1.6a1 1 0 0 0-1.4 0L17 4v3Z', key: '15ixgv' }],
  ['path', { d: 'm17 7-5.1 5.1', key: 'l9guh7' }],
  ['circle', { cx: '11.5', cy: '12.5', r: '.5', fill: 'currentColor', key: '16onso' }],
  [
    'path',
    {
      d: 'M6 12a2 2 0 0 0 1.8-1.2l.4-.9C8.7 8.8 9.8 8 11 8c2.8 0 5 2.2 5 5 0 1.2-.8 2.3-1.9 2.8l-.9.4A2 2 0 0 0 12 18a4 4 0 0 1-4 4c-3.3 0-6-2.7-6-6a4 4 0 0 1 4-4',
      key: 'x9fguj',
    },
  ],
  ['path', { d: 'm6 16 2 2', key: '16qmzd' }],
]);

export default Guitar;
