import createLucideIcon from '../createLucideIcon';

/**
 * @component @name TestTubes
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNOSAydjE3LjVBMi41IDIuNSAwIDAgMSA2LjUgMjJ2MEEyLjUgMi41IDAgMCAxIDQgMTkuNVYyIiAvPgogIDxwYXRoIGQ9Ik0yMCAydjE3LjVhMi41IDIuNSAwIDAgMS0yLjUgMi41djBhMi41IDIuNSAwIDAgMS0yLjUtMi41VjIiIC8+CiAgPHBhdGggZD0iTTMgMmg3IiAvPgogIDxwYXRoIGQ9Ik0xNCAyaDciIC8+CiAgPHBhdGggZD0iTTkgMTZINCIgLz4KICA8cGF0aCBkPSJNMjAgMTZoLTUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/test-tubes
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const TestTubes = createLucideIcon('TestTubesIcon', [
  ['path', { d: 'M9 2v17.5A2.5 2.5 0 0 1 6.5 22v0A2.5 2.5 0 0 1 4 19.5V2', key: '12z67u' }],
  ['path', { d: 'M20 2v17.5a2.5 2.5 0 0 1-2.5 2.5v0a2.5 2.5 0 0 1-2.5-2.5V2', key: '1q2nfy' }],
  ['path', { d: 'M3 2h7', key: '7s29d5' }],
  ['path', { d: 'M14 2h7', key: '7sicin' }],
  ['path', { d: 'M9 16H4', key: '1bfye3' }],
  ['path', { d: 'M20 16h-5', key: 'ddnjpe' }],
]);

export default TestTubes;
