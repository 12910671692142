import createLucideIcon from '../createLucideIcon';

/**
 * @component @name Dna
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMiAxNWM2LjY2Ny02IDEzLjMzMyAwIDIwLTYiIC8+CiAgPHBhdGggZD0iTTkgMjJjMS43OTgtMS45OTggMi41MTgtMy45OTUgMi44MDctNS45OTMiIC8+CiAgPHBhdGggZD0iTTE1IDJjLTEuNzk4IDEuOTk4LTIuNTE4IDMuOTk1LTIuODA3IDUuOTkzIiAvPgogIDxwYXRoIGQ9Im0xNyA2LTIuNS0yLjUiIC8+CiAgPHBhdGggZD0ibTE0IDgtMS0xIiAvPgogIDxwYXRoIGQ9Im03IDE4IDIuNSAyLjUiIC8+CiAgPHBhdGggZD0ibTMuNSAxNC41LjUuNSIgLz4KICA8cGF0aCBkPSJtMjAgOSAuNS41IiAvPgogIDxwYXRoIGQ9Im02LjUgMTIuNSAxIDEiIC8+CiAgPHBhdGggZD0ibTE2LjUgMTAuNSAxIDEiIC8+CiAgPHBhdGggZD0ibTEwIDE2IDEuNSAxLjUiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/dna
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const Dna = createLucideIcon('DnaIcon', [
  ['path', { d: 'M2 15c6.667-6 13.333 0 20-6', key: '1pyr53' }],
  ['path', { d: 'M9 22c1.798-1.998 2.518-3.995 2.807-5.993', key: 'q3hbxp' }],
  ['path', { d: 'M15 2c-1.798 1.998-2.518 3.995-2.807 5.993', key: '80uv8i' }],
  ['path', { d: 'm17 6-2.5-2.5', key: '5cdfhj' }],
  ['path', { d: 'm14 8-1-1', key: '15nbz5' }],
  ['path', { d: 'm7 18 2.5 2.5', key: '16tu1a' }],
  ['path', { d: 'm3.5 14.5.5.5', key: 'hapbhd' }],
  ['path', { d: 'm20 9 .5.5', key: '1n7z02' }],
  ['path', { d: 'm6.5 12.5 1 1', key: 'cs35ky' }],
  ['path', { d: 'm16.5 10.5 1 1', key: '696xn5' }],
  ['path', { d: 'm10 16 1.5 1.5', key: '11lckj' }],
]);

export default Dna;
