import createLucideIcon from '../createLucideIcon';

/**
 * @component @name DnaOff
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTUgMmMtMS4zNSAxLjUtMi4wOTIgMy0yLjUgNC41TTkgMjJjMS4zNS0xLjUgMi4wOTItMyAyLjUtNC41IiAvPgogIDxwYXRoIGQ9Ik0yIDE1YzMuMzMzLTMgNi42NjctMyAxMC0zbTEwLTNjLTEuNSAxLjM1LTMgMi4wOTItNC41IDIuNSIgLz4KICA8cGF0aCBkPSJtMTcgNi0yLjUtMi41IiAvPgogIDxwYXRoIGQ9Im0xNCA4LTEuNS0xLjUiIC8+CiAgPHBhdGggZD0ibTcgMTggMi41IDIuNSIgLz4KICA8cGF0aCBkPSJtMy41IDE0LjUuNS41IiAvPgogIDxwYXRoIGQ9Im0yMCA5IC41LjUiIC8+CiAgPHBhdGggZD0ibTYuNSAxMi41IDEgMSIgLz4KICA8cGF0aCBkPSJtMTYuNSAxMC41IDEgMSIgLz4KICA8cGF0aCBkPSJtMTAgMTYgMS41IDEuNSIgLz4KICA8bGluZSB4MT0iMiIgeDI9IjIyIiB5MT0iMiIgeTI9IjIyIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/dna-off
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const DnaOff = createLucideIcon('DnaOffIcon', [
  ['path', { d: 'M15 2c-1.35 1.5-2.092 3-2.5 4.5M9 22c1.35-1.5 2.092-3 2.5-4.5', key: 'sxiaad' }],
  ['path', { d: 'M2 15c3.333-3 6.667-3 10-3m10-3c-1.5 1.35-3 2.092-4.5 2.5', key: 'yn4bs1' }],
  ['path', { d: 'm17 6-2.5-2.5', key: '5cdfhj' }],
  ['path', { d: 'm14 8-1.5-1.5', key: '1ohn8i' }],
  ['path', { d: 'm7 18 2.5 2.5', key: '16tu1a' }],
  ['path', { d: 'm3.5 14.5.5.5', key: 'hapbhd' }],
  ['path', { d: 'm20 9 .5.5', key: '1n7z02' }],
  ['path', { d: 'm6.5 12.5 1 1', key: 'cs35ky' }],
  ['path', { d: 'm16.5 10.5 1 1', key: '696xn5' }],
  ['path', { d: 'm10 16 1.5 1.5', key: '11lckj' }],
  ['line', { x1: '2', x2: '22', y1: '2', y2: '22', key: 'a6p6uj' }],
]);

export default DnaOff;
