import createLucideIcon from '../createLucideIcon';

/**
 * @component @name FileCog
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNNCAyMmgxNGEyIDIgMCAwIDAgMi0yVjdsLTUtNUg2YTIgMiAwIDAgMC0yIDJ2MiIgLz4KICA8cGF0aCBkPSJNMTQgMnY0YTIgMiAwIDAgMCAyIDJoNCIgLz4KICA8Y2lyY2xlIGN4PSI2IiBjeT0iMTQiIHI9IjMiIC8+CiAgPHBhdGggZD0iTTYgMTB2MSIgLz4KICA8cGF0aCBkPSJNNiAxN3YxIiAvPgogIDxwYXRoIGQ9Ik0xMCAxNEg5IiAvPgogIDxwYXRoIGQ9Ik0zIDE0SDIiIC8+CiAgPHBhdGggZD0ibTkgMTEtLjg4Ljg4IiAvPgogIDxwYXRoIGQ9Ik0zLjg4IDE2LjEyIDMgMTciIC8+CiAgPHBhdGggZD0ibTkgMTctLjg4LS44OCIgLz4KICA8cGF0aCBkPSJNMy44OCAxMS44OCAzIDExIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/file-cog
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const FileCog = createLucideIcon('FileCogIcon', [
  ['path', { d: 'M4 22h14a2 2 0 0 0 2-2V7l-5-5H6a2 2 0 0 0-2 2v2', key: '17k7jt' }],
  ['path', { d: 'M14 2v4a2 2 0 0 0 2 2h4', key: 'tnqrlb' }],
  ['circle', { cx: '6', cy: '14', r: '3', key: 'a1xfv6' }],
  ['path', { d: 'M6 10v1', key: 'xs0f9j' }],
  ['path', { d: 'M6 17v1', key: 'idyhc0' }],
  ['path', { d: 'M10 14H9', key: 'm5fm2q' }],
  ['path', { d: 'M3 14H2', key: '19ot09' }],
  ['path', { d: 'm9 11-.88.88', key: 'lhul2b' }],
  ['path', { d: 'M3.88 16.12 3 17', key: '169z9n' }],
  ['path', { d: 'm9 17-.88-.88', key: '5io96w' }],
  ['path', { d: 'M3.88 11.88 3 11', key: '1ynhy1' }],
]);

export default FileCog;
