import createLucideIcon from '../createLucideIcon';

/**
 * @component @name NotebookPen
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTMuNCAySDZhMiAyIDAgMCAwLTIgMnYxNmEyIDIgMCAwIDAgMiAyaDEyYTIgMiAwIDAgMCAyLTJ2LTcuNCIgLz4KICA8cGF0aCBkPSJNMiA2aDQiIC8+CiAgPHBhdGggZD0iTTIgMTBoNCIgLz4KICA8cGF0aCBkPSJNMiAxNGg0IiAvPgogIDxwYXRoIGQ9Ik0yIDE4aDQiIC8+CiAgPHBhdGggZD0iTTE4LjQgMi42YTIuMTcgMi4xNyAwIDAgMSAzIDNMMTYgMTFsLTQgMSAxLTRaIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/notebook-pen
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const NotebookPen = createLucideIcon('NotebookPenIcon', [
  ['path', { d: 'M13.4 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-7.4', key: 're6nr2' }],
  ['path', { d: 'M2 6h4', key: 'aawbzj' }],
  ['path', { d: 'M2 10h4', key: 'l0bgd4' }],
  ['path', { d: 'M2 14h4', key: '1gsvsf' }],
  ['path', { d: 'M2 18h4', key: '1bu2t1' }],
  ['path', { d: 'M18.4 2.6a2.17 2.17 0 0 1 3 3L16 11l-4 1 1-4Z', key: '1dba1m' }],
]);

export default NotebookPen;
