import createLucideIcon from '../createLucideIcon';

/**
 * @component @name MousePointer2
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJtNCA0IDcuMDcgMTcgMi41MS03LjM5TDIxIDExLjA3eiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/mouse-pointer-2
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const MousePointer2 = createLucideIcon('MousePointer2Icon', [
  ['path', { d: 'm4 4 7.07 17 2.51-7.39L21 11.07z', key: '1vqm48' }],
]);

export default MousePointer2;
