import createLucideIcon from '../createLucideIcon';

/**
 * @component @name AlignJustify
 * @description Lucide SVG icon component, renders SVG Element with children.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8bGluZSB4MT0iMyIgeDI9IjIxIiB5MT0iNiIgeTI9IjYiIC8+CiAgPGxpbmUgeDE9IjMiIHgyPSIyMSIgeTE9IjEyIiB5Mj0iMTIiIC8+CiAgPGxpbmUgeDE9IjMiIHgyPSIyMSIgeTE9IjE4IiB5Mj0iMTgiIC8+Cjwvc3ZnPgo=) - https://lucide.dev/icons/align-justify
 * @see https://lucide.dev/guide/packages/lucide-vue-next - Documentation
 *
 * @param {Object} props - Lucide icons props and any valid SVG attribute
 * @returns {FunctionalComponent} Vue component
 *
 */
const AlignJustify = createLucideIcon('AlignJustifyIcon', [
  ['line', { x1: '3', x2: '21', y1: '6', y2: '6', key: '4m8b97' }],
  ['line', { x1: '3', x2: '21', y1: '12', y2: '12', key: '10d38w' }],
  ['line', { x1: '3', x2: '21', y1: '18', y2: '18', key: 'kwyyxn' }],
]);

export default AlignJustify;
